import * as React from "react";
import { css, useTheme } from "@emotion/react";

import Navigation from "../Navigation";
import Footer from "../Footer";
import TableList from "../TableList";
import Seo from "../Seo";
import Player from "../Player";
import { isBrowser } from "../utils";

import ArrowRight from "../ArrowRight";
import PinIcon from "../PinIcon";
import CameraIcon from "../CameraIcon";
import FormIcon from "../FormIcon";
import StarIcon from "../StarIcon";
import TrophyIcon from "../TrophyIcon";
import phrases from "../constants/zasadyPhrases";
import audio from "../../static/zasady_gry.mp3";
import audioEn from "../../static/game_rules.mp3";

const constainerStyle = (theme) => css`
  min-height: 100vh;
  background-color: ${theme.colors.red};
  color: ${theme.colors.whiteText};

  section {
    scroll-margin-top: 75px;
  }
`;

const innerContainerStyle = css`
  padding: 0 20px;
`;

const rulesSectionStyle = (theme) => css`
  ${innerContainerStyle};
  max-width: 1800px;
  margin: 0 auto;

  h1,
  h2 {
    ${theme.emotionTypography.lead};
    margin: 0;
    padding-top: 20px;
    line-height: 1em;
  }
`;

const prizesContainerStyle = (theme) => css`
  ${rulesSectionStyle(theme)};
`;

const prizesContentContainerStyle = (theme) => css`
  ${theme.grids.thirtyToOne};
  padding-top: 100px;
`;

const paperListLead = (theme) => css`
  ${theme.emotionTypography.smallLead};
  margin-bottom: 150px;
  text-indent: 3em;
  max-width: 900px;

  ${theme.breakpoints.mobile} {
    margin-bottom: 30px;
  }
`;

const paperVersionSectionStyle = (theme) => css`
  ${innerContainerStyle};
  background-color: ${theme.colors.darkGrey};
  color: ${theme.colors.whiteText};
  ${theme.grids.thirtyToOne};
  padding-top: 100px;
  padding-bottom: 150px;
`;

const iconsContainerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
`;

const stepsContainerStyle = (theme) => css`
  ${theme.grids.steps};
  column-gap: 50px;
  border-top: 1px solid currentColor;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const rulesParagraphStyle = css`
  padding-bottom: 50px;
  max-width: 550px;
  margin-left: auto;
`;

const ZasadyGryPage = ({ pageContext }) => {
  const theme = useTheme();
  const language = pageContext?.language || "pl";
  const otherLanguageUrl = language === "pl" ? "/en/game-rules" : "/zasady-gry";
  return (
    <>
      <Seo
        language={language}
        title={language === "pl" ? "Zasady gry" : "Game rules"}
      />
      <div css={constainerStyle}>
        <Navigation
          foreground={theme.colors.whiteText}
          background={theme.colors.red}
          language={language}
          languageUrl={otherLanguageUrl}
        >
          <ul>
            <li>
              <a href="#zasady">{phrases[24][language]}</a>
            </li>
            <li>
              <a href="#wersja-papierowa">{phrases[25][language]}</a>
            </li>
            <li>
              <a href="#nagrody">{phrases[26][language]}</a>
            </li>
          </ul>
        </Navigation>
        <section css={rulesSectionStyle} id="zasady">
          <h1>{phrases[7][language]}</h1>
          <p css={paperListLead}>{phrases[8][language]}</p>
          {isBrowser() && (
            <Player
              file={language === "pl" ? audio : audioEn}
              language={language}
            />
          )}
          <p css={rulesParagraphStyle}>{phrases[9][language]}</p>
          <h3>{phrases[10][language]}</h3>
          <div css={stepsContainerStyle}>
            <div>
              <div css={iconsContainerStyle}>
                <PinIcon height="100" />
                <ArrowRight height="80" />
              </div>
              <p>{phrases[12][language]}</p>
            </div>
            <div>
              <div css={iconsContainerStyle}>
                <CameraIcon height="80" />
                <ArrowRight height="80" />
              </div>
              <p>{phrases[13][language]}</p>
            </div>
            <div>
              <div css={iconsContainerStyle}>
                <FormIcon height="80" />
                <ArrowRight height="80" />
              </div>
              <p>{phrases[14][language]}</p>
            </div>
            <div>
              <div css={iconsContainerStyle}>
                <StarIcon height="80" />
                <ArrowRight height="80" />
              </div>
              <p>{phrases[15][language]}</p>
            </div>
            <div>
              <div css={iconsContainerStyle}>
                <TrophyIcon height="80" />
                <ArrowRight height="80" />
              </div>
              <p>{phrases[16][language]}</p>
            </div>
          </div>
        </section>
        <section css={paperVersionSectionStyle} id="wersja-papierowa">
          <div></div>
          <div>
            <p css={paperListLead}>{phrases[17][language]}</p>
            <TableList>
              <ol>
                <li>
                  <span>{phrases[18][language]}</span>
                  <span>{phrases[19][language]}</span>
                </li>
                <li>
                  <span>{phrases[20][language]}</span>
                  <span>{phrases[21][language]}</span>
                </li>
                <li>
                  <span>{phrases[22][language]}</span>
                  <span>{phrases[23][language]}</span>
                </li>
              </ol>
            </TableList>
          </div>
        </section>
        <section css={prizesContainerStyle} id="nagrody">
          <h2>{phrases[0][language]}</h2>
          <div css={prizesContentContainerStyle}>
            <div></div>
            <TableList>
              <ol>
                <li>
                  <span>{phrases[1][language]}</span>
                  <span>{phrases[2][language]}</span>
                </li>
                <li>
                  <span>{phrases[3][language]}</span>
                  <span>{phrases[4][language]}</span>
                </li>
                <li>
                  <span>{phrases[5][language]}</span>
                  <span>{phrases[6][language]}</span>
                </li>
              </ol>
            </TableList>
          </div>
        </section>
      </div>
      <Footer language={language} />
    </>
  );
};

export default ZasadyGryPage;

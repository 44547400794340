import * as React from "react";

const phrases = [
  //0
  {
    pl: "Nagrody",
    en: "Prizes",
  },
  //1
  {
    pl: "1 miejsce",
    en: "1 place",
  },
  //2
  {
    pl: "Warsztaty ceramiczne w Zakwas Studio",
    en: "Ceramic workshops in Zakwas Studio",
  },
  //3
  {
    pl: "2 miejsce",
    en: "2 place",
  },
  //4
  {
    pl: "Grafika w technice Riso zaprojektowana przez Szymona Szewczyka + pakiet wydawnictw BWA Wrocław",
    en: "Graphics in Riso technique designed by Szymon Szewczyk + BWA Wrocław publishing package",
  },
  //5
  {
    pl: "3 miejsce",
    en: "3 place",
  },
  //6
  {
    pl: "Grafika w technice Riso zaprojektowana przez Szymona Szewczyka",
    en: "Graphics in Riso technique designed by Szymon Szewczyk",
  },
  //7
  {
    pl: "Zasady gry",
    en: "Game rules",
  },
  //8
  {
    pl: (
      <>
        Do wzięcia udziału w grze potrzebujesz smartfona z funkcją czytania
        kodów QR i aparatem
      </>
    ),
    en: (
      <>
        To participate in the game you need a smartphone with a QR code reading
        function (i.e. QR Droid, Barcode Scanner, Esponce QR Reader) and a
        camera
      </>
    ),
  },
  //9
  {
    pl: (
      <>
        Na mapie zaznaczonych jest 6 punktów znajdujących się na terenie
        Kościuszkowskiej Dzielnicy Mieszkaniowej. Poruszaj się po niej w celu
        odnalezienia wszystkich zaznaczonych miejsc. Po znalezieniu wszystkich
        punktów uzupełnij formularz dostępny na stronie glassgo.bwa.wroc.pl i
        wykonaj zadanie finałowe.
      </>
    ),
    en: (
      <>
        On the map, you will find 6 points located in the Kosciuszko Housing
        District. Move along it to find all the places marked on the map. After
        finding all the points, complete the form available on
        glassgo.bwa.wroc.pl and complete the FINAL TASK
      </>
    ),
  },
  //10
  {
    pl: "Jak się gra?",
    en: "How do I play?",
  },
  //11
  {
    pl: "Pobierz mapkę z galerii SIC! BWA Wrocław (pl. Kościuszki 9/10), w DCF (ul. Piłsudskiego  64a) oraz Green Caffe Nero (pl. Kościuszki 1) lub wejdź na stronę gry glassgo.bwa.wroc.pl.",
    en: "Collect the map from the SIC! BWA Wrocław gallery, DCF (Piłsudskiego Street  64a) or Green Caffe Nero (Kościuszki Square 1) or visit the game on glassgo.bwa.wroc.pl.",
  },
  //12
  {
    pl: "Zlokalizuj na mapie wszystkie punkty na osiedlu KDM oznaczone za pomocą QR kodów i udaj się tam.",
    en: "Locate all points in the KDM housing estate on the map marked with QR codes and go there.",
  },
  //13
  {
    pl: "Po dotarciu na miejsce znajdź tabliczkę z zakodowaną podpowiedzią. Odczytaj wiadomość. Rozpoznaj opisany obiekt i zrób mu zdjęcie.",
    en: "After reaching the place, find the plate with a coded hint. Read the message. Recognize the described subject and take a photo of it.",
  },
  //14
  {
    pl: "Jeśli uda ci się odnaleźć wszystkie punkty – czas wysłać nam swój formularz z odpowiedziami. Znajdziesz go na glassgo.bwa.wroc.pl Na tym etapie przydatne będą wykonane podczas gry zdjęcia.",
    en: "After you manage to find all the points  send us your completed form with all the answers. You can find it on www.glassgo.bwa.wrco.pl .The photos made during the game will be useful at this stage.",
  },
  //15
  {
    pl: "Jeśli wypełnisz poprawnie formularz – dostaniesz e-mailem ZADANIE FINAŁOWE.",
    en: "After sending the form with the correct answers, you will receive a final task by e-mail.",
  },
  //16
  {
    pl: "Wykonaj zadanie finałowe i zakończ grę",
    en: "Complete the final task and end the game!",
  },
  //17
  {
    pl: "Dla graczy wolących tradycyjne rozwiązania przygotowaliśmy mapy. Można je nieodpłatnie pobrać w miejscach z poniższej listy.",
    en: "If you prefer traditional solutions we have preapred printed maps. You can collect them for free from the spots  listed below.",
  },
  //18
  {
    pl: "galeria SIC! BWA Wrocław",
    en: "SIC! BWA Wrocław gallery",
  },
  //19
  {
    pl: "pl. Kościuszki 9/10",
    en: "pl. Kościuszki 9/10",
  },
  //20
  {
    pl: "DCF",
    en: "DCF",
  },
  //21
  {
    pl: "ul. Piłsudskiego 64a",
    en: "ul. Piłsudskiego 64a",
  },
  //22
  {
    pl: "Green Caffe Nero",
    en: "Green Caffe Nero",
  },
  //23
  {
    pl: "pl. Kościuszki 1",
    en: "pl. Kościuszki 1",
  },
  //24
  {
    pl: "Zasady Gry",
    en: "Game rules",
  },
  //25
  {
    pl: "Wersja papierowa",
    en: "Paper version",
  },
  //26
  {
    pl: "Nagrody",
    en: "Prizes",
  },
];

export default phrases;

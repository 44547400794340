import * as React from "react";

const PinIcon = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      {...props}
    >
      <path
        fill="currentColor"
        d="M121.87,85.17c14.16-15.67,38.34-16.9,54.02-2.74c15.67,14.16,16.9,38.34,2.74,54.02
	c-14.16,15.67-38.34,16.9-54.02,2.74C108.94,125.02,107.71,100.84,121.87,85.17z M135.11,97.12c-7.56,8.36-6.9,21.27,1.46,28.83
	c8.36,7.56,21.27,6.9,28.83-1.46c7.56-8.36,6.9-21.27-1.46-28.83C155.57,88.1,142.66,88.76,135.11,97.12z"
      />
      <path
      fill="currentColor"
        d="M110.33,184.88c-4.58-7.1-9.17-14.2-13.75-21.3c-2.29-3.55-4.58-7.1-6.87-10.65c-2.37-3.67-3.87-6.01-5.78-9.95
	c-6.9-14.23-9.05-30.3-6.11-45.84c6.2-32.83,33.68-57.37,66.99-59.84c33.3-2.46,64.1,17.77,75.06,49.32
	c5.25,15.11,5.44,31.52,0.55,46.75c-1.19,3.71-2.65,7.27-4.41,10.75c-1.97,3.88-3.69,6.47-6.05,10.11
	c-4.63,7.17-9.25,14.34-13.88,21.51c-1.18,1.83-2.36,3.66-3.54,5.49c-0.59,0.92-1.18,1.83-1.77,2.75c-0.07,0.11-0.15,0.23-0.22,0.34
	c-0.03,0.05,0.05,0.02,0.08,0.03c0.03,0.01,0.06,0.01,0.09,0.02c0.06,0.01,0.12,0.03,0.18,0.04c0.24,0.06,0.49,0.11,0.73,0.17
	c0.49,0.11,0.97,0.23,1.45,0.35c3.88,0.96,7.7,2.08,11.47,3.4c7.41,2.59,14.89,6.09,21.3,10.73c6.42,4.64,12.02,10.64,15.13,18.15
	c3.12,7.55,3.31,15.69,0.47,23.36c-5.79,15.67-22.09,24.77-36.09,29.79c-14.88,5.34-31.7,7.84-47.37,8.37
	c-15.75,0.53-32.56-0.9-47.91-4.71c-14.42-3.58-31.63-10.31-41.84-22.55c-11.28-13.54-11.19-30.69,0.17-44.15
	C78.68,195.12,95.9,188.43,110.33,184.88z M197.2,141.1c9.5-14.71,11.55-33.03,5.54-49.48c-2.79-7.62-7.04-14.33-12.75-20.11
	c-6.5-6.57-14.53-11.43-23.37-14.14c-16.73-5.13-34.9-2.12-49.09,8.13c-14.2,10.25-22.76,26.57-23.14,44.08
	c-0.19,8.54,1.59,17,5.18,24.75c2.02,4.35,3.89,7.01,6.47,11.01c2.41,3.73,4.82,7.46,7.22,11.19c9.33,14.45,18.65,28.9,27.98,43.35
	c2.33,3.61,4.66,7.22,6.99,10.84c0.58,0.9,1.17,1.81,1.75,2.71c0.07,0.11,0.15,0.23,0.22,0.34c0.02,0.02,0.02,0.11,0.05,0.06
	c0.02-0.03,0.04-0.06,0.05-0.08c0.04-0.06,0.07-0.11,0.11-0.17c0.15-0.23,0.29-0.45,0.44-0.68c0.29-0.45,0.58-0.9,0.87-1.35
	c1.17-1.81,2.33-3.61,3.5-5.42c4.66-7.22,9.33-14.45,13.99-21.67C178.55,170,187.87,155.55,197.2,141.1z M120.65,200.86
	c-9.38,1.84-19.45,4.81-27.9,9.55c-3.29,1.84-6.3,3.9-9.01,6.57c-2.09,2.06-3.77,4.22-4.92,6.96c-2.65,6.33-0.48,12.13,3.8,16.86
	c3.01,3.33,6.88,5.97,10.77,8.09c5.17,2.81,10.55,4.84,16.17,6.5c15.38,4.53,32.95,6.09,48.85,5.46c15.52-0.61,33.62-3.45,48-10.19
	c4.69-2.2,9.46-5.03,13.2-8.74c2.62-2.6,4.85-5.74,5.68-9.47c0.71-3.22,0.24-6.34-1.17-9.28c-1.85-3.85-5.02-6.94-8.35-9.4
	c-4.4-3.26-9.14-5.57-14.2-7.54c-6.11-2.38-12.34-4.06-18.76-5.33c-0.84-0.17-1.69-0.33-2.53-0.48c-0.06-0.01-0.11-0.04-0.15,0.03
	c-0.02,0.03-0.03,0.05-0.05,0.08c-0.03,0.05-0.07,0.1-0.1,0.15c-0.07,0.1-0.13,0.21-0.2,0.31c-0.27,0.41-0.53,0.83-0.8,1.24
	c-0.53,0.83-1.07,1.65-1.6,2.48c-1.07,1.65-2.13,3.3-3.2,4.95c-4.46,6.91-8.92,13.82-13.38,20.73c-2.23,3.46-4.46,6.91-6.69,10.37
	c-1.12,1.73-2.23,3.46-3.35,5.18c-0.14,0.22-0.28,0.43-0.42,0.65c-0.02,0.03-0.03,0.05-0.05,0.08c-0.01,0.02-0.03,0.06-0.05,0.03
	c-0.03-0.05-0.07-0.11-0.1-0.16c-0.07-0.11-0.14-0.22-0.21-0.32c-0.28-0.43-0.56-0.86-0.84-1.3c-0.56-0.86-1.12-1.73-1.67-2.59
	C138.49,228.5,129.57,214.68,120.65,200.86z"
      />
    </svg>
  );
};

export default PinIcon;

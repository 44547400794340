import * as React from "react";

const FormIcon = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      {...props}
    >
      <path
        fill="currentColor"
        d="M189.89,273.12H63.91V40.94h173.4v184.18L189.89,273.12z M212.44,225.12h-22.54v22.82L212.44,225.12z
	 M172.19,207.42h47.42V58.64H81.61v196.78h90.58V207.42z"
      />
      <rect x="96.37" y="82.04" fill="currentColor" width="106.55" height="17.7" />
      <rect x="96.37" y="117.93" fill="currentColor" width="106.55" height="17.7" />
      <rect x="96.37" y="153.83" fill="currentColor" width="106.55" height="17.7" />
      <rect x="96.37" y="189.72" fill="currentColor" width="58.27" height="17.7" />
    </svg>
  );
};

export default FormIcon;

import * as React from "react";

const CameraIcon = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      {...props}
    >
      <path
        fill="currentColor"
        d="M207.35,159.88c0,31.33-25.4,56.73-56.73,56.73c-31.33,0-56.73-25.4-56.73-56.73s25.4-56.73,56.73-56.73
	C181.95,103.15,207.35,128.55,207.35,159.88z M189.65,159.88c0-21.56-17.48-39.04-39.04-39.04c-21.56,0-39.04,17.48-39.04,39.04
	s17.48,39.04,39.04,39.04C172.17,198.92,189.65,181.44,189.65,159.88z"
      />
      <path
        fill="currentColor"
        d="M243.95,78.87h35.91v174.01H21.37V78.87h74.61l18.21-17.7h36.43h36.43l18.21,17.7h20.48v-17.7h18.21V78.87z
	 M198.07,96.57l-18.21-17.7h-58.48l-18.21,17.7H39.07v138.61h223.09V96.57H198.07z"
      />
      <rect x="57.94" y="115.59" fill="currentColor" width="18.21" height="17.7" />
    </svg>
  );
};

export default CameraIcon;

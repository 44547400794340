import * as React from "react";

const TrophyIcon = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      {...props}
    >
      <path
        fill="currentColor"
        d="M201.46,148.82c-4.11,7.86-8.77,15.19-14.46,22.03c-4.84,5.81-10.08,10.9-16.35,15.21
   c-2.7,1.86-5.5,3.49-8.47,4.89c-0.7,0.33-1.41,0.64-2.12,0.94c-0.18,0.07-0.35,0.15-0.53,0.22c-0.05,0.02-0.13,0-0.13,0.09
   c0,0.02,0,0.04,0,0.07c0,0.09,0,0.18,0,0.26c0,0.35,0,0.7,0,1.05c0,1.41,0,2.81,0,4.22c0,2.81,0,5.62,0,8.44c0,1.41,0,2.81,0,4.22
   c0,0.18,0,0.35,0,0.53c0,0.04,0,0.09,0,0.13c0,0.04,0,0.07,0.05,0.08c0.08,0.01,0.16,0.02,0.24,0.04c0.32,0.05,0.64,0.1,0.96,0.16
   c0.64,0.11,1.29,0.23,1.93,0.37c11.11,2.31,21.31,7.75,29.4,15.7c7.24,7.11,12.53,15.95,15.37,25.7c1.34,4.6,2.09,9.19,2.3,13.97
   c0.02,0.57,0.04,1.13,0.05,1.7c0,0.28,0.01,0.56,0.01,0.84c0,0.03-0.03,0.01-0.05,0.01c-0.02,0-0.04,0-0.06,0c-0.04,0-0.08,0-0.11,0
   c-0.08,0-0.15,0-0.23,0c-0.15,0-0.31,0-0.46,0c-1.22,0-2.45,0-3.67,0c-2.45,0-4.89,0-7.34,0c-19.58,0-39.15,0-58.73,0
   c-9.75,0-19.5,0-29.26,0c-4.88,0-9.75,0-14.63,0c-1.22,0-2.44,0-3.66,0c-0.04,0-0.08,0-0.11,0c-0.06,0-0.01-0.06-0.01-0.09
   c0-0.07,0-0.14,0-0.21c0-0.14,0-0.28,0-0.42c0-0.28,0.01-0.56,0.02-0.85c0.02-0.57,0.04-1.13,0.07-1.7c0.13-2.33,0.39-4.63,0.8-6.93
   c3.51-20.06,17.09-36.9,35.95-44.58c3.64-1.48,7.34-2.58,11.2-3.32c0.46-0.09,0.93-0.17,1.39-0.25c0.23-0.04,0.46-0.08,0.69-0.11
   c0.06-0.01,0.12-0.02,0.17-0.03c0.06-0.01,0.02-0.14,0.02-0.17c0-0.13,0-0.25,0-0.38c0-1.02,0-2.03,0-3.05c0-2.03,0-4.06,0-6.09
   s0-4.06,0-6.09c0-1.02,0-2.03,0-3.05c0-0.06,0-0.13,0-0.19c0-0.04-0.04-0.02-0.06-0.03c-0.03-0.01-0.06-0.03-0.1-0.04
   c-0.06-0.03-0.13-0.05-0.19-0.08c-0.13-0.05-0.26-0.11-0.38-0.16c-0.51-0.22-1.02-0.44-1.53-0.67c-1.04-0.47-2.06-0.98-3.06-1.52
   c-2.11-1.13-4.14-2.37-6.11-3.74c-9.26-6.41-16.87-15.1-23.06-24.33c-1.81-2.7-3.5-5.46-5.11-8.28c-0.83-1.46-1.64-2.94-2.42-4.43
   c-0.05-0.1-0.14-0.12-0.23-0.16c-0.09-0.04-0.18-0.08-0.27-0.11c-0.18-0.08-0.36-0.15-0.54-0.23c-0.36-0.15-0.71-0.31-1.07-0.47
   c-0.71-0.31-1.42-0.63-2.12-0.96c-5.59-2.58-10.95-5.5-16.11-8.86c-16.61-10.8-30.84-25.88-38.06-44.9
   c-2.97-7.85-4.61-16.06-4.76-24.46c-0.03-1.98,0.02-3.96,0.15-5.93c0.01-0.12,0.02-0.25,0.03-0.37c0-0.06,0.01-0.13,0.01-0.19
   c0-0.03,0-0.06,0.01-0.09c0-0.02,0-0.03,0-0.05c0-0.01-0.03-0.04,0-0.04c0.06,0,0.12,0,0.17,0c0.12,0,0.23,0,0.35,0
   c0.23,0,0.46,0,0.69,0c0.92,0,1.84,0,2.76,0c7.37,0,14.74,0,22.11,0c4.27,0,8.53,0,12.8,0c0.27,0,0.53,0,0.8,0c0.13,0,0.27,0,0.4,0
   c0.03,0,0.07,0,0.1,0c0.01,0,0.01-0.01,0.01-0.01c0-0.03,0-0.06-0.01-0.09c0-0.06-0.01-0.12-0.01-0.18
   c-0.01-0.12-0.02-0.24-0.03-0.37c-0.03-0.49-0.07-0.98-0.1-1.47c-0.25-3.87-0.42-7.74-0.52-11.62c-0.02-0.95-0.04-1.91-0.05-2.86
   c0-0.24-0.01-0.48-0.01-0.71c0-0.12,0-0.24,0-0.36c0-0.03,0.02-0.03,0.05-0.03c0.07,0,0.13,0,0.2,0c0.27,0,0.53,0,0.8,0
   c0.53,0,1.07,0,1.6,0c2.13,0,4.27,0,6.4,0c8.53,0,17.07,0,25.6,0c17.07,0,34.13,0,51.2,0c17.07,0,34.13,0,51.2,0
   c2.13,0,4.27,0,6.4,0c1.07,0,2.13,0,3.2,0c0.53,0,1.07,0,1.6,0c0.13,0,0.27,0,0.4,0c0.02,0,0.03,0,0.05,0
   c0.05,0,0.01,0.05,0.01,0.08c0,0.06,0,0.12,0,0.18c0,0.24-0.01,0.47-0.01,0.71c-0.01,0.48-0.01,0.95-0.02,1.43
   c-0.04,1.92-0.09,3.84-0.16,5.76c-0.07,1.94-0.17,3.88-0.28,5.82c-0.06,0.98-0.12,1.96-0.19,2.93c-0.02,0.24-0.03,0.49-0.05,0.73
   c0,0.03,0,0.05,0.04,0.05c0.02,0,0.03,0,0.05,0c0.07,0,0.13,0,0.2,0c0.53,0,1.07,0,1.6,0c1.07,0,2.13,0,3.2,0c2.13,0,4.27,0,6.4,0
   c8.53,0,17.07,0,25.6,0c0.52,0,1.04,0,1.56,0c0.26,0,0.52,0,0.78,0c0.13,0,0.26,0,0.39,0c0.07,0,0.13,0,0.2,0c0.03,0,0.07,0,0.1,0
   c0.02,0,0.03,0,0.05,0c0.08,0,0.03,0.08,0.03,0.12c0.35,4.53,0.26,9-0.27,13.51c-1.15,9.88-4.33,19.28-9.15,27.94
   C243.21,124.76,223.01,139.74,201.46,148.82z M78.8,79.76c-5.15,0-10.31,0-15.46,0c-1.29,0-2.58,0-3.87,0c-0.64,0-1.29,0-1.93,0
   c-0.32,0-0.64,0-0.97,0c-0.16,0-0.32,0-0.48,0c-0.08,0-0.16,0-0.24,0c-0.05,0-0.2-0.04-0.18,0.05c0.59,2.52,1.35,4.97,2.28,7.38
   c3.73,9.69,9.93,18.2,17.35,25.29c3.75,3.58,7.75,6.77,12.04,9.68c0.25,0.17,0.5,0.34,0.75,0.51c0.13,0.08,0.25,0.17,0.38,0.25
   c0.06,0.04,0.13,0.08,0.19,0.12c0.03,0.02,0.06,0.04,0.1,0.06c0.02,0.01,0.03,0.02,0.05,0.03c0.01,0,0.02,0.01,0.02,0
   c-0.02-0.07-0.04-0.13-0.07-0.2c-1.43-4.25-2.71-8.54-3.88-12.88C82.19,100.07,80.21,90,78.8,79.76z M113.07,251.98
   c14.05,0,28.11,0,42.16,0c7.03,0,14.05,0,21.08,0c2.12,0,4.24,0,6.35,0c1.06,0,2.12,0,3.18,0c0.53,0,1.06,0,1.59,0
   c0.13,0,0.26,0,0.4,0c0.07,0,0.13,0,0.2,0c0.07,0-0.01-0.07-0.03-0.11c-0.08-0.16-0.15-0.32-0.23-0.48
   c-1.29-2.63-2.81-5.05-4.62-7.35c-8.88-11.29-22.97-17.18-37.25-15.56C131.62,230.1,119.2,238.99,113.07,251.98z M222.32,79.76
   c-1.42,10.24-3.4,20.31-6.08,30.3c-1.16,4.33-2.45,8.62-3.88,12.88c-0.02,0.07-0.04,0.13-0.07,0.2c0,0.01,0.01,0,0.02,0
   c0.02-0.01,0.03-0.02,0.05-0.03c0.03-0.02,0.06-0.04,0.09-0.06c0.06-0.04,0.13-0.08,0.19-0.12c0.13-0.08,0.25-0.17,0.38-0.25
   c0.25-0.17,0.5-0.34,0.75-0.51c4.29-2.91,8.29-6.09,12.03-9.68c7.43-7.1,13.62-15.6,17.35-25.29c0.93-2.41,1.69-4.86,2.28-7.38
   c0.02-0.09-0.13-0.05-0.18-0.05c-0.08,0-0.16,0-0.24,0c-0.16,0-0.32,0-0.48,0c-0.32,0-0.64,0-0.97,0c-0.64,0-1.29,0-1.93,0
   c-1.29,0-2.58,0-3.87,0C232.62,79.76,227.47,79.76,222.32,79.76z M150.56,176.56c0.18-0.06,0.37-0.12,0.55-0.18
   c1.79-0.62,3.53-1.39,5.21-2.27c1.94-1.03,3.79-2.21,5.55-3.51c2.05-1.5,3.98-3.16,5.81-4.91c2.1-2.01,4.06-4.15,5.91-6.39
   c2.08-2.52,4.01-5.16,5.82-7.88c2-3,3.84-6.11,5.56-9.28c1.86-3.44,3.56-6.96,5.13-10.54c1.67-3.81,3.18-7.68,4.57-11.6
   c1.45-4.09,2.74-8.24,3.91-12.42c1.2-4.3,2.26-8.64,3.19-13c1.89-8.83,3.25-17.77,4.15-26.76c0.19-1.91,0.36-3.82,0.51-5.74
   c-18.62,0-37.25,0-55.87,0s-37.25,0-55.87,0c0.15,1.91,0.32,3.83,0.51,5.74c0.9,8.99,2.26,17.93,4.15,26.76
   c0.93,4.36,1.99,8.7,3.19,13c1.17,4.18,2.46,8.33,3.91,12.42c1.38,3.92,2.9,7.79,4.57,11.6c1.57,3.58,3.27,7.1,5.12,10.54
   c1.71,3.17,3.56,6.28,5.56,9.28c1.81,2.72,3.74,5.36,5.82,7.88c1.85,2.24,3.81,4.38,5.91,6.39c1.83,1.76,3.77,3.41,5.81,4.91
   c1.77,1.3,3.62,2.48,5.55,3.51c1.68,0.89,3.41,1.66,5.21,2.27C150.19,176.44,150.37,176.5,150.56,176.56"
      />
      <polygon
        fill="currentColor"
        points="138.99,113.11 121.01,99.99 143.26,100.03 150.15,78.91 157,100.06 179.36,100.1 161.26,113.2 
   168.12,134.35 150.15,121.24 132.07,134.32 "
      />
    </svg>
  );
};

export default TrophyIcon;

import * as React from "react";

const StarIcon = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      {...props}
    >
      <path
        fill="currentColor"
        d="M98.68,179.75l-82.58-60.24l102.17,0.19l31.65-97.04l31.49,97.16l102.5,0.19l-82.97,60.04l31.53,97.27
	l-82.65-60.28l-82.87,59.97L98.68,179.75z M119.49,173.02l-18.84,57.77l49.21-35.61l48.94,35.7l-18.67-57.6l49.27-35.66l-60.87-0.11
	L149.86,79.9l-18.76,57.52l-60.57-0.11L119.49,173.02z"
      />
    </svg>
  );
};

export default StarIcon;
